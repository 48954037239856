import { render, staticRenderFns } from "./Selection.vue?vue&type=template&id=af4bf5da&scoped=true&"
import script from "./Selection.vue?vue&type=script&lang=js&"
export * from "./Selection.vue?vue&type=script&lang=js&"
import style0 from "@/assets/less/selection/selection.less?vue&type=style&index=0&id=af4bf5da&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af4bf5da",
  null
  
)

export default component.exports