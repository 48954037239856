<template>
  <div class="selection col-all-12">
    <UnitPopup
      :showVehicle="unitPopupShowVehicle"
      :active="isUnitPopupActive"
      :exitOnClose="true"
      @close="isUnitPopupActive = false"
      @saved="unitSaved()"
    />

    <div class="patreons-ticker col-f col-pc-8 col-lgmobile-10 col-mdmobile-12 col-smobile-12 uk-margin-auto">
      <div class="left">
        <p class="col-mobile-displaynone">{{ $t('selection.patreons') }}</p>
        <i class="fab fa-patreon col-pc-displaynone"></i>
      </div>
      <div class="right">
        <marquee-text
          :duration="20"
          :repeat="20"
          class="col-all-12"
        >
          <div class="ticker-wrap col-all-12">
            <div
              v-for="patron in patrons"
              :key="patron.id"
              class="ticker__tier"
              :class="{
                'ticker-tier-one': patron.tier === 'TIER_2',
                'ticker-tier-two': patron.tier === 'TIER_3',
                'ticker-tier-three': patron.tier === 'TIER_4',
                'ticker-sponsor': patron.tier === 'SPONSOR_TIER_1'
              }"
            >
              {{ patron.name }}
            </div>
            <div
              v-if="patrons.length === 0"
              class="ticker-sponsor ticker__tier"
            >
              {{ $t('selection.your-name-could-appear-here') }}
            </div>
          </div>
        </marquee-text>
      </div>
    </div>

    <a
      class="patreon-banner uk-banner banner primary col-pc-8 col-lgmobile-10 col-mdmobile-12 col-smobile-12 uk-margin-auto"
      href="https://www.patreon.com/leafcad"
      target="_blank"
    >
      <div class="icon col-all-1">
        <i class="fab fa-patreon"></i>
      </div>
      <div class="text col-all-10">
        <p>{{ $t('selection.support-us-on-patreon') }}</p>
      </div>
      <div class="button col-all-1">
        <i class="fas fa-long-arrow-alt-right"></i>
      </div>
    </a>
    <div class="box col-pc-8 col-lgmobile-10 col-mdmobile-12 col-smobile-12 uk-margin-auto">
      <div class="top">
        <div class="col-all-12">
          <h1 class="title primary">
            <span>{{ $t('selection.select-your') }}</span>&nbsp;
            <span>{{ $t('selection.' + this.$route.name + '-singular') }}</span>
          </h1>
          <router-link :to="{ name: 'profile-general' }">
            <i class="fas fa-user-cog v-step-profile-button"></i>
          </router-link>
        </div>
        <div>
          <p v-if="$route.name !== 'communities' && $store.state.selection.community !== null">
            {{ $t('selection.selected-community') }}:
            <router-link :to="{ name: 'communities' }">{{
              $store.state.selection.community.community.name
            }}</router-link>
          </p>
          <p v-if="$route.name !== 'communities' && $route.name !== 'roles' && $store.state.selection.role !== null">
            {{ $t('selection.selected-role') }}:
            <router-link :to="{ name: 'roles' }">{{
              $store.state.selection.role.charAt(0).toUpperCase() + $store.state.selection.role.slice(1)
            }}</router-link>
          </p>
        </div>
        <div class="error">
          <p
            class="orange"
            v-for="error in errors"
            :key="error"
          >{{ error }}</p>
        </div>
      </div>
      <div class="content">
        <router-view
          ref="currentPage"
          @doubleClickSelect="next()"
          @error="setErrors"
        ></router-view>
      </div>
      <div class="bottom col-pc-10 col-mobile-12">
        <div class="return col-pc-6 col-lgmobile-6 col-mdmobile-12 col-smobile-12">
          <button
            class="primary-full col-pc-6 col-lgmobile-6 col-mdmobile-6 col-smobile-8"
            @click="back()"
          >
            <p>
              {{
                $route.name !== 'communities'
                  ? $store.state.selection.addingCharacter
                    ? $t('actions.cancel')
                    : $t('actions.back')
                  : $t('actions.disconnect')
              }}
            </p>
          </button>
        </div>
        <div class="continue col-pc-6 col-lgmobile-6 col-mdmobile-12 col-smobile-12">
          <button
            class="primary-full col-pc-6 col-lgmobile-6 col-mdmobile-6 col-smobile-8 v-step-continue-button"
            @click="next()"
          >
            <p>{{ $store.state.selection.addingCharacter ? $t('actions.add') : $t('actions.continue') }}</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/selection/selection.less"></style>

<script>
import { mapGetters } from 'vuex'
import EnforcePermissionsForCommunity from '@/mixins/EnforcePermissionsForCommunity'
import UnitPopup from '@/components/UnitPopup'
import MarqueeText from 'vue-marquee-text-component'

export default {
  name: 'selection',
  mixins: [EnforcePermissionsForCommunity],
  components: {
    UnitPopup,
    MarqueeText
  },
  data: function() {
    return {
      errors: {},
      isUnitPopupActive: false,
      unitPopupShowVehicle: true,
      patrons: []
    }
  },
  apollo: {
    patrons: {
      query: require('@/graphql/selection/fetchPatrons.gql'),
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    ...mapGetters('auth', ['checkCurrentPermissions']),
    setErrors: function(value) {
      this.errors = value
    },
    next: function() {
      this.errors = {}
      if (this.$route.name === 'communities') {
        if (this.$store.state.selection.community !== null) {
          if (this.checkCurrentPermissions()('accepted') || this.checkCurrentPermissions()('owner')) {
            this.$store.dispatch('selection/selectCommunity').then(response => {
              this.$router.push({ name: 'roles' })
            })
          } else {
            this.errors = Object.assign({}, this.errors, { community: this.$t('errors.NOT_ACCEPTED') })
            this.$store.dispatch('errorManager/showError', { error: 'NOT_ACCEPTED' })
          }
        } else {
          // Object.assign triggers reactivity but no just =, https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
          this.errors = Object.assign({}, this.errors, { community: this.$t('errors.NO_COMMUNITY_SELECTED') })
          this.$store.dispatch('errorManager/showError', { error: 'NO_COMMUNITY_SELECTED' })
        }
      } else if (this.$route.name === 'roles') {
        if (this.$store.state.selection.role !== null) {
          let selectedRole = this.$store.state.selection.role
          if (selectedRole === 'admin') {
            this.$router.push({ name: 'admin-general' })
          } else {
            this.$router.push({ name: 'characters' })
          }
        } else {
          this.errors = Object.assign({}, this.errors, { role: this.$t('errors.NO_ROLE_SELECTED') })
          this.$store.dispatch('errorManager/showError', { error: 'NO_ROLE_SELECTED' })
        }
      } else if (this.$route.name === 'characters') {
        let selectedRole = this.$store.state.selection.role
        if (this.$store.state.selection.addingCharacter) {
          this.$refs.currentPage
            .addCharacter()
            .then(character => {
              this.$store.commit('selection/character', character)
              this.$store.dispatch('selection/addingCharacter', false)
            })
            .catch(err => {
              if (err.graphQLErrors) {
                err.graphQLErrors.map(({ extensions }) => {
                  this.errors = Object.assign({}, this.errors, {
                    character: this.$t(`errors.${Object.values(extensions)[0]}`)
                  })
                  this.fieldsErrors = {
                    email: true,
                    password: true
                  }
                })
              } else {
                this.errors = Object.assign({}, this.errors, err)
              }
            })
        } else {
          if (this.$store.state.selection.character) {
            if (selectedRole === 'police') {
              this.unitPopupShowVehicle = true
              this.isUnitPopupActive = true
            } else if (selectedRole === 'civilian') {
              this.unitSaved()
            } else if (selectedRole === 'dispatch') {
              this.unitPopupShowVehicle = false
              this.isUnitPopupActive = true
            }
          } else {
            this.errors = Object.assign({}, this.errors, { character: this.$t('errors.NO_CHARACTER_SELECTED') })
            this.$store.dispatch('errorManager/showError', { error: 'NO_CHARACTER_SELECTED' })
          }
        }
      } else if (this.$route.name === 'departments') {
      }
    },
    back: function() {
      this.errors = {}
      if (this.$route.name === 'communities') {
        this.$swal({
          title: this.$t('selection.are-you-sure'),
          text: this.$t('selection.you-will-be-disconnected'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('actions.disconnect')
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('auth/logout').then(response => {
              this.$router.push({ name: 'login' })
            })
          }
        })
      } else if (this.$route.name === 'roles') {
        this.$router.push({ name: 'communities' })
      } else if (this.$route.name === 'characters') {
        if (this.$store.state.selection.addingCharacter) {
          this.$store.dispatch('selection/addingCharacter', false)
        } else {
          this.$router.push({ name: 'roles' })
        }
      } else if (this.$route.name === 'departments') {
        this.$router.push({ name: 'characters' })
      }
    },
    unitSaved: function() {
      const selectedRole = this.$store.state.selection.role
      if (selectedRole === 'police') {
        this.$router.push({ name: 'police' }) // TODO: Go to department
      } else if (selectedRole === 'civilian') {
        this.$router.push({ name: 'civilian' })
      } else if (selectedRole === 'dispatch') {
        this.$router.push({ name: 'dispatch' })
      }
    }
  }
}
</script>
